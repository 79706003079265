@import "../global";

// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Bootstrap Overrides
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Pages
@import 'pages/dashboard';

@import url('https://cdn.jsdelivr.net/npm/flatpickr/dist/flatpickr.min.css');